import { DiscordIcon } from '#components/svg/discord';
import { TooltipSpan } from 'components/Tooltip/TooltipSpan';
import constants from '#lib/constants';
import Link from 'next/link';
import Image from 'next/image';
import cn from 'classnames';
import { Divider } from '#components/Divider/Divider';

const navigation = {
  creations: [
    { name: 'Featured', href: '#' },
    { name: 'Collections', href: '/collections' },
    { name: 'Explore', href: '/marketplace/explore' },
    { name: 'Launchpad', href: '/create' },
    { name: 'Verify Collection', href: '/verify' },
  ],
  about: [
    { name: 'About Us', href: 'https://docs.dropspot.io/' },
    { name: 'Blog', href: 'https://blog.dropspot.io/' },
    { name: 'Help Center', href: 'https://dropspot.gitbook.io/guides/' },
  ],
  legal: [
    { name: 'Privacy Policy', href: '/privacy' },
    { name: 'Terms of Use', href: '/terms' },
  ],
  social: [
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/dropspot.io/',
      icon: function icon() {
        return (
          <div className="text-gray-500 w-6 h-6">
            <svg fill="currentColor" viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        );
      },
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/dropspot_io',
      icon: function icon() {
        return (
          <div className="text-gray-500 w-6 h-6">
            <svg fill="currentColor" viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </div>
        );
      },
    },
    {
      name: 'Discord',
      href: 'https://discord.gg/wrXJY7TCvV',
      icon: function icon() {
        return (
          <div className="text-gray-500 w-6 h-6">
            <DiscordIcon />
          </div>
        );
      },
    },
  ],
};

type FooterProps = {
  mini?: boolean;
};

export default function Footer({ mini }: FooterProps) {
  if (mini)
    return (
      <>
        <div className="h-[3rem] bg-base-300 dark:bg-base-200  z-20">HI</div>
      </>
    );

  return (
    <footer className="bg-base-200" aria-labelledby="footer-heading">
      <div className="mx-auto max-w-8xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-4 -mt-4">
            <div className="flex-shrink-0 items-center flex dark:hidden pt-1 sm:pt-2">
              <Link href="/">
                <a>
                  <Image
                    width="120"
                    height="24"
                    className="block w-auto"
                    src="/img/drop-logo-black.webp"
                    alt="Dropspot Logo"
                  />
                </a>
              </Link>
            </div>
            <div className="flex-shrink-0 items-center hidden  dark:flex pt-1 sm:pt-2">
              <Link href="/">
                <a>
                  <Image
                    width="120"
                    height="24"
                    className="block w-auto"
                    src="/img/drop-logo-white.webp"
                    alt="Dropspot Logo"
                  />
                </a>
              </Link>
            </div>
            <p className="dark:text-white font-medium">
              NFT MARKETPLACE FOR CARDANO
            </p>
            <p className="text-sm leading-6 text-gray-600 dark:text-gray-400">
              A launchpad for creators, a marketplace for Cardano NFTs. Find
              original art, trade and connect with a global community of
              creatives.
            </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <item.icon />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div></div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-400">
                  Support
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.creations.map((item) => (
                    <li key={item.name}>
                      {' '}
                      <Link href={item.href}>
                        <a className="text-base text-gray-500 hover:text-gray-900 dark:hover:text-white">
                          {item.name}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-400">
                  Company
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {' '}
                  {navigation.about.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target={'_blank'}
                        className="text-base text-gray-500 hover:text-gray-900 dark:hover:text-white"
                        rel="noreferrer"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-400">
                  Legal
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-500">
            &copy; Dropspot. Onchain. All rights immutable.
          </p>
        </div>
      </div>
    </footer>
  );
}
