import { getShimmerB64, getShimmerB64Dark } from '#lib/icons/Shimmer';
import { FunctionComponent, useState } from 'react';
import { useDarkSide } from '#lib/hooks/useDarkSide';
interface LoadingProps {
  fullScreen?: boolean;
}
export const Loading: FunctionComponent<LoadingProps> = ({
  fullScreen = true,
}) => {
  const { colorTheme, setTheme } = useDarkSide();
  const [darkSide, setDarkSide] = useState<boolean>(
    colorTheme === 'light' ? true : false
  );

  return (
    <div
      className={fullScreen ? 'w-screen h-screen' : 'w-full h-full dark:hidden'}
    >
      <img
        className="w-full h-full"
        src={`data:image/svg+xml;base64,${getShimmerB64}`}
        alt="Loading..."
      />
    </div>
  );
};
