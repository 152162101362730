import React from 'react';
import cn from 'classnames';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@nextui-org/react';

export interface TextInputProps {
  id: string;
  label: string;
  type?: string;
  maxLength?: number;
  min?: string;
  autoComplete?: string;
  gapped?: boolean;
  rounded?: boolean;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  value?: string | number | undefined;
  infoText?: string | undefined;
  onChange: (text: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: string;
  labelPosition?: 'sr-only' | 'top' | 'start' | 'inside' | 'hero';
  className?: string;
  inputClassName?: string;
  icon?: () => JSX.Element;
  autoFocus?: boolean;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement>;
}

export function TextInput({
  id,
  label,
  type = 'text',
  maxLength,
  min,
  autoComplete,
  required,
  placeholder,
  value = '',
  infoText,
  onChange,
  onBlur,
  onFocus,
  gapped,
  rounded,
  error,
  className,
  inputClassName,
  disabled,
  labelPosition = 'sr-only',
  autoFocus = false,
  icon,
  onPaste,
}: TextInputProps) {
  return (
    <div
      className={cn(className, {
        'mb-6 dark:bg-gray-800': gapped,
      })}
    >
      {labelPosition != 'inside' && (
        <label
          htmlFor={id}
          className={cn(
            { 'sr-only': labelPosition === 'sr-only' },
            {
              'text-sm m-1 mx-0 font-normal': labelPosition !== 'sr-only',
            }
          )}
        >
          {label}
        </label>
      )}
      <div className="mt-1 relative rounded-md">
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {icon()}
          </div>
        )}
        {labelPosition == 'inside' && (
          <label
            htmlFor={id}
            className={
              'absolute inset-y-0 left-0 pl-3 flex pt-3 text-gray-600 font-light text-sm pointer-events-none'
            }
          >
            {label}
          </label>
        )}
        {labelPosition == 'inside' && !!infoText && (
          <span className="w-4 h-4 absolute inset-y-0 right-3 top-3">
            <Tooltip content={infoText} placement={'left'}>
              <InformationCircleIcon className="w-5 h-5 text-gray-500 font-light text-sm hover:text-secondary" />
            </Tooltip>
          </span>
        )}
        <input
          autoFocus={autoFocus}
          value={value || ''}
          onChange={({ currentTarget: { value } }) => {
            (!maxLength || value.length <= maxLength) && onChange(value);
          }}
          onPaste={onPaste}
          id={id}
          name={id}
          type={type}
          min={min}
          onBlur={() => onBlur && onBlur()}
          onFocus={() => onFocus && onFocus()}
          autoComplete={autoComplete}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          className={cn(
            `${inputClassName}  text-gray-900 dark:text-gray-100 h-10 block w-full px-3 py-2 dark:bg-base-200  outline-secondary dark:outline-secondary `,
            {
              'pl-10': !!icon,
            },
            {
              'h-20 text-lg font-medium pt-10': labelPosition == 'inside',
            },
            {
              'h-36 text-8xl font-medium bg-gray-50 dark:bg-gray-800':
                labelPosition == 'hero',
            },
            {
              'cursor-not-allowed bg-gray-100 dark:bg-gray-800':
                disabled == true,
            },
            {
              'rounded-full': rounded == true,
            },
            {
              'rounded-md': rounded != true,
            }
          )}
        />
        {maxLength && (
          <div className="flex items-end justify-end">
            <p className="text-gray-500 text-sm m-1">
              {(value as string).length}/{maxLength}
            </p>
          </div>
        )}
        {error && (
          <ExclamationCircleIcon className="w-6 h-6 absolute right-2 top-0 bottom-0 m-auto text-error-500" />
        )}
      </div>
      {error && (
        <p
          className="text-sm text-error-600 w-full bg-red-100 py-3 px-3 rounded-lg mt-2"
          id={`${id}-error`}
          data-testid={`${id}-error`}
        >
          {error}
        </p>
      )}
    </div>
  );
}
