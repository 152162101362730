const shimmer = `<svg width="100%" height="100%" version="1.1" 
  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#fff" offset="10%" />
      <stop stop-color="#fafafa" offset="50%" />
      <stop stop-color="#fff" offset="90%" />
    </linearGradient>
  </defs>
  <rect width="100%" height="100%" fill="#fff" />
  <rect id="r" width="100%" height="100%" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-100%" to="100%" dur="1s" repeatCount="indefinite"  />
</svg>`;

const shimmerDark = `<svg width="100%" height="100%" version="1.1" 
  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#151515" offset="10%" />
      <stop stop-color="#303030" offset="50%" />
      <stop stop-color="#151515" offset="90%" />
    </linearGradient>
  </defs>
  <rect width="100%" height="100%" fill="#fff" />
  <rect id="r" width="100%" height="100%" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-100%" to="100%" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str);

export const getShimmerB64 = toBase64(shimmer);

export const getShimmerB64Dark = toBase64(shimmerDark);
