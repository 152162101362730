import constants from '#lib/constants';
import { CountryCode } from 'libphonenumber-js';
import { useEffect, useState } from 'react';

export const useIpCountry = () => {
  const [defaultCountry, setCountry] = useState<CountryCode | undefined>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (typeof window === 'undefined') return;

    fetch('https://ipinfo.io/country', {
      headers: {
        Authorization: `Bearer ${constants.NEXT_PUBLIC_IPINFO}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Bad response');

        return response.text();
      })
      .then((country) => setCountry(country.replace('\n', '') as CountryCode))
      .catch(() => setCountry('US'))
      .finally(() => setLoading(false));
  }, []);

  return {
    loading,
    defaultCountry,
  };
};
