import React from 'react';
import cn from 'classnames';
import { CheckIcon } from '@heroicons/react/24/solid';

export interface CheckboxProps {
  checked: boolean;
  enabled?: boolean;
  onChange: (checked: boolean) => void;
  size?: 'small' | 'medium' | 'large' | 'xs';
}

export function Checkbox({
  checked,
  size = 'large',
  onChange,
  enabled = true,
}: CheckboxProps) {
  return (
    <div
      onClick={() => {
        enabled && onChange(!checked);
      }}
      className={cn(
        'cursor-pointer border-2 border-gray-500 rounded-lg flex flex-initial items-center justify-center',
        {
          'bg-black': checked,
        },
        { 'h-6 w-6 border-1': size === 'xs' },
        { 'h-8 w-8 border-1': size === 'small' },
        { 'h-12 w-12 border-2': size === 'medium' },
        { 'h-14 w-14 border-2': size === 'large' },
        { 'cursor-not-allowed': enabled != true }
      )}
    >
      {checked && <CheckIcon className="w-8 h-8 text-white" />}
    </div>
  );
}
