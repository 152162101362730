import { BellAlertIcon } from '@heroicons/react/24/solid';

export type NotificationProps = {
  message: string;
};

export const ErrorNotification = ({ message }: NotificationProps) => {
  return (
    <div className="w-80 border-l-4 border-red-600 bg-red-50 dark:bg-gray-700 p-4 text-sm flex items-center shadow-lg relative z-50 mt-36 rounded-md">
      <button
        type="button"
        className="hidden absolute right-0 top-1.5 w-4 text-red-600 m-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      <div className="w-5 h-5 text-red-500 mr-2 flex-shrink-0">
        <BellAlertIcon></BellAlertIcon>
      </div>
      <p className="dark:text-white">{message}</p>
    </div>
  );
};
export const SuccessNotification = ({ message }: NotificationProps) => {
  return (
    <div className="w-80  border-l-4 border-green-600 bg-green-50 dark:bg-gray-700 p-4 text-sm flex items-center shadow-lg relative z-50 mt-36 rounded-md">
      <button
        type="button"
        className="absolute hidden right-0 top-1.5 w-4 text-green-600 m-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      <div className="w-5 h-5 text-green-500 mr-2 flex-shrink-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <p className="dark:text-white">{message}</p>
    </div>
  );
};
export const BlackNotification = ({ message }: NotificationProps) => {
  return (
    <div className="w-96 text-center gap-2 bg-black px-2 py-1 text-sm flex items-center justify-between shadow-lg relative z-50 rounded-full mt-1">
      <div className="w-5 h-5 text-white mr-2 flex-shrink-0">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <p className="text-white">{message}</p>
      <button type="button" className="w-5 h-5 text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
};
